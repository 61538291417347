.content-providers-form {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.providers-header {
  margin-top: 15px;
  width: 470px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  border-bottom: 1px solid lightgrey;

  & > span:nth-child(2) {
    margin-left: 85px;
  }
}

.provider-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-left: 5px;
  width: 60%;

  & > div.field {
    margin: 0 !important;
  }

  & > label {
    width: 180px;
    font-size: 14px;
  }
}

.statistics-access {
  width: 40%;
  height: 557px;
  margin-top: 43px;
  padding: 17px 15px 30px 15px;
  background-color: #F1F1F1;
}

.is-provider-checkbox {
  margin-bottom: 43px !important;
}

.ui.disabled.fitted.checkbox {
  border: 1px solid rgba(166, 166, 166, 0.4) !important;
  border-radius: 3px;
}

.label-disabled {
  opacity: 0.35;
}