.info-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 17px;
  font-weight: 700;
  font-size: 15px;
  border-radius: 50%;
  text-align: center;
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  margin-top: 7px;
}

.region-label {
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: rgba(0,0,0,.6);
}

.middle-section {
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: start;

  &__left {
    width: 60%;
  }

  &__right {
    width: 40%;
  }
}

.ui.disabled.input {
  opacity: 0.9;
}

.ui.form .disabled.field {
  opacity: 0.9;
}